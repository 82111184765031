<template>
    <div class="bag-content">
        <div class="bag-top">
            <el-button type="primary" @click="createBag">创建福袋数据</el-button>
        </div>
        <el-table :data="bagList" class="customTable" style="width: 100%; margin-top: 20px" height="1%">
            <el-table-column prop="bag_name" label="福袋数据名称">
                <template slot-scope="scope">
                    <div class="bag-name">{{scope.row.bag_name}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="name" label="行业分类" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-link type="primary" :underline="false" @click="editBtn(scope.row)">编辑</el-link>
                    <el-link type="danger" :underline="false" @click="delBtn(scope.row)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pages-center" style="margin: 20px 0"
                       :current-page="listPages.currentPageNum"
                       :page-size="listPages.eachPageNum"
                       layout="prev, pager, next, jumper"
                       :total="listPages.total"
                       @current-change="pageCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import {bagList, delBag} from '@/utils/apis'

    export default {
        name: "Index",
        data() {
            return {
                bagList: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
            }
        },
        mounted() {
            this.getBagList()
        },
        methods: {
            getBagList() {
                let params = {
                    paging: '1',
                    page: this.listPages.currentPageNum,
                    limit: this.listPages.eachPageNum,
                }
                bagList(params).then(res => {
                    this.bagList = res.data.list
                    this.listPages.total = res.data.total
                })
            },
            createBag() {
                this.$router.push('/bagManage/create')
            },
            pageCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getBagList()
            },
            editBtn(row) {
                this.$router.push({
                    path: '/bagManage/create',
                    query: {
                        id: row.id
                    }
                })
            },
            delBtn(row) {
                this.$confirm('删除后将无法恢复，确定要删除？', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'custom-message',
                    type: 'warning'
                }).then(() => {
                    delBag(row.id).then((res) => {
                        this.$message.success(res.msg)
                        this.getBagList()
                    })
                }).catch(() => {
                    this.$message.info('已取消删除');
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .bag-content {
        margin: 20px;
        height: calc(100% - 40px);
        display: flex;
        flex-direction: column;

        .bag-top {
            text-align: right;
        }

        .bag-name {
            width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
</style>